import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>Sorry, the page you're looking for does not exist.</p>
      {/* You can add a link to the homepage or other relevant pages */}
    </div>
  );
};

export default NotFound;
