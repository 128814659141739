import React from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import currency from '../../Tools/currency';

const PaymentCollectionGenerator = (props) => {
    const { data, totalCollection, fromDate, toDate } = props;
    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title and other text to the PDF
        doc.setFontSize(8); // Make text size smaller
        doc.setFont('helvetica', 'bold');
        doc.text('ILOILO MERCHANT MARINE SCHOOL', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
        doc.setFont('helvetica', 'normal');
        doc.text('STUDENT ACCOUNTING OFFICE', doc.internal.pageSize.getWidth() / 2, 25, { align: 'center' });
        doc.text('Cabugao Sur, Pavia, 5001, Iloilo', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' });
        doc.text('Tel. No.: (033) 327-9973', doc.internal.pageSize.getWidth() / 2, 35, { align: 'center' });
        doc.setFontSize(10); 
        doc.setTextColor('red')
        doc.text('PAYMENT COLLECTION', doc.internal.pageSize.getWidth() / 2, 45, { align: 'center' });

        doc.setFontSize(8); // Smaller text for the date range
        doc.setTextColor('black')
        doc.text('FROM:', 15, 55);
        doc.text(`${fromDate}`, 26, 55);
        doc.text('TO:', 45, 55);
        doc.text(`${toDate}`, 51, 55);

        doc.setCharSpace(-0.5);
        doc.setFont('Helvetica', 'bold');
        doc.setTextColor('red')
        doc.setFontSize(10); // Slightly larger for the total collection
        doc.text(`TOTAL COLLECTION: ${currency.formatter.format(totalCollection)}`, (doc.internal.pageSize.getWidth() / 2) + 90, 55, { align: 'right' });
        doc.setCharSpace(0);
        doc.setTextColor('black')
        
        // Add table to the PDF
        const tableColumn = ["DATE", "STUDENT NAME", "STUDENT ID", "DESCRIPTION", "OR #", "MODE", "PAID AMT."];
        const tableRows = data.map((row) => [ row.PaymentDate, row.StudentName, row.StudentID, row.Description, row.ORNo, row.Method, row.Amount ]);

        autoTable(doc, {
            startY: 75,
            head: [tableColumn],
            body: tableRows,
            styles: { halign: 'left', fontSize: 8 }, 
            headStyles: { fillColor: [34, 139, 34], fontSize: 8 }, 
        });

        // Create PDF URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        
        // Open PDF in a new tab
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.addEventListener('load', () => {
                newWindow.document.title = 'Payment Collection Report';
            });
        }
    };

    return (
        <div>
            <button className='btn btn-primary' onClick={generatePDF}>PRINT</button>
        </div>
    );
};

export default PaymentCollectionGenerator;